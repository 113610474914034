import { cn } from '@finn/ui-utils';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { useKeenSlider } from 'keen-slider/react';
import React, { useMemo, useState } from 'react';

import { TestimonialSlideMetadata } from '../Testimonial.types';
import { getSlidePosition } from '../Testimonial.utils';
import TestimonialSliderMobileSlide from './TestimonialSliderMobileSlide';

const useStyles = makeStyles(() => ({
  root: {},

  slider: {
    backgroundSize: '100% 50%',
  },

  slide: {
    paddingLeft: 16,
  },

  slideTexts: {
    gap: 16,
    padding: '36px 16px',
    marginTop: -1,
  },

  slideTextsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 24,
  },

  headingWrapper: {
    minHeight: 52,
  },

  customerText: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
}));

type Props = {
  slides: TestimonialSlideMetadata[];
};

const TestimonialSliderMobile: React.FC<Props> = ({ slides }) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);

  const displayedSlides = useMemo(
    () => (slides.length <= 2 ? [...slides, ...slides] : slides),
    [slides]
  );

  const keenSliderOptions = useMemo(
    () => ({
      loop: true,

      slides: {
        perView: 1.5,
      },

      slideChanged: (keenInstance) => {
        setCurrentIndex(keenInstance.track.details.rel);
      },
    }),
    []
  );

  const [sliderRef] = useKeenSlider(keenSliderOptions, []);

  return (
    <div ref={sliderRef} className={classes.root}>
      <div
        className={classNames(
          classes.slider,
          'keen-slider',
          'bg-gradient-to-b from-black to-black bg-bottom bg-no-repeat'
        )}
      >
        {displayedSlides.map((slide, index) => (
          <div
            className={classNames(classes.slide, 'keen-slider__slide')}
            key={`${slide.name}${index >= slides.length ? '-repeated' : ''}`}
          >
            <TestimonialSliderMobileSlide
              slide={slide}
              position={getSlidePosition(currentIndex, index)}
            />
          </div>
        ))}
      </div>

      <div className={cn(classes.slideTexts, 'bg-black')}>
        <div className={classes.slideTextsContainer}>
          <div className={classes.headingWrapper}>
            <h4 className="global-t5-semibold text-white">
              {displayedSlides[currentIndex]?.description}
            </h4>
          </div>

          <div className={classes.customerText}>
            <h6 className="global-t6-semibold text-white">
              {displayedSlides[currentIndex]?.name}
            </h6>

            <span className="body-16-regular text-white">
              {displayedSlides[currentIndex]?.vehicle_model}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TestimonialSliderMobile);
