import { makeStyles } from '@material-ui/core';
import React from 'react';

import {
  TrustPilotRating,
  TrustpilotStyle,
} from '~/ui-elements/TrustPilotRating';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  mobileTrustpilot: {
    left: -40,
  },
}));

const TestimonialSlider: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TrustPilotRating
        style={TrustpilotStyle.Testimonial}
        mobileClassName={classes.mobileTrustpilot}
        width="420px"
        mobileWidth="250px"
        mobileHeight="42px"
        desktopHeight="42px"
      />
    </div>
  );
};

export default TestimonialSlider;
