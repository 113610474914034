import { cn } from '@finn/ui-utils';
import { makeStyles } from '@material-ui/core';
import throttle from 'lodash/throttle';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { TestimonialSlideMetadata } from '~/modules/cosmic/modules/Testimonial';
import {
  BREAKPOINT_SM_1_VALUE,
  BREAKPOINT_SM_2_VALUE,
  SLIDE_TRANSITION_DESKTOP_MS,
} from '~/modules/cosmic/modules/Testimonial/Testimonial.constants';
import { getSlidePosition } from '~/modules/cosmic/modules/Testimonial/Testimonial.utils';

import TestimonialSliderDesktopNextButton from './TestimonialSliderDesktopNextButton';
import TestimonialSliderDesktopSlide from './TestimonialSliderDesktopSlide';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 420,
    position: 'relative',

    [theme.breakpoints.up('xs')]: {
      marginRight: 72,
    },

    [theme.breakpoints.up('sm')]: {
      marginRight: 56,
    },

    [theme.breakpoints.up(BREAKPOINT_SM_1_VALUE)]: {
      height: 360,
    },

    [theme.breakpoints.up(BREAKPOINT_SM_2_VALUE)]: {
      height: 420,
    },
  },

  buttonContainer: {
    width: 80,
    height: 80,
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: 'auto',
    zIndex: 2,

    // -80px of button width -24px spacing
    right: -104,

    [theme.breakpoints.down('sm')]: {
      right: -40,
    },
  },
}));

type Props = {
  slides: TestimonialSlideMetadata[];
};

type SliderState = {
  currentIndex: number;
  isAnimated: boolean;
};

const TestimonialSliderDesktop: React.FC<Props> = ({ slides }) => {
  const classes = useStyles();

  const displayedSlides = useMemo(
    () => [...slides, ...slides.slice(0, 2)],
    [slides]
  );

  const [sliderState, setSliderState] = useState<SliderState>({
    currentIndex: 0,
    isAnimated: true,
  });

  const debouncedSetSliderState = useMemo(
    () =>
      throttle(setSliderState, SLIDE_TRANSITION_DESKTOP_MS, {
        leading: true,
        trailing: false,
      }),
    []
  );

  const handleNextClick = useCallback(() => {
    debouncedSetSliderState((prevSliderState) => ({
      currentIndex: prevSliderState.currentIndex + 1,
      isAnimated: true,
    }));
  }, [debouncedSetSliderState]);

  useEffect(() => {
    if (sliderState.currentIndex === slides.length) {
      window.setTimeout(() => {
        setSliderState({ currentIndex: 0, isAnimated: false });
      }, SLIDE_TRANSITION_DESKTOP_MS + 16);
    }
  }, [sliderState, slides.length]);

  const renderSlide = useCallback(
    (slide: TestimonialSlideMetadata, index: number) => {
      const key = `${slide.name}${index >= slides.length ? '-repeated' : ''}`;
      const isRepeated = index >= slides.length;
      const isHidden = Math.abs(sliderState.currentIndex - index) > 1;
      const position = getSlidePosition(sliderState.currentIndex, index);

      return (
        <TestimonialSliderDesktopSlide
          key={key}
          slide={slide}
          position={position}
          isAnimated={sliderState.isAnimated}
          isHidden={isHidden}
          isRepeated={isRepeated}
        />
      );
    },
    [sliderState, slides.length]
  );

  return (
    <div className={cn(classes.root, 'bg-black')}>
      {displayedSlides.map(renderSlide)}

      <div className={classes.buttonContainer}>
        <TestimonialSliderDesktopNextButton onClick={handleNextClick} />
      </div>
    </div>
  );
};

export default React.memo(TestimonialSliderDesktop);
