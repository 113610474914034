import { makeStyles } from '@material-ui/core';
import React from 'react';

import { parseToHtml } from '~/utils/html';

import { TestimonialCosmicMetadata } from './Testimonial.types';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 36,
    marginRight: 36,
  },
}));

type Props = {
  data: TestimonialCosmicMetadata;
};

const TestimonialDescription: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2 className="mobile-t3-semibold sm:web-t2-semibold">
        {parseToHtml(data.title)}
      </h2>

      <div className="body-16-regular">{data.description}</div>
    </div>
  );
};

export default React.memo(TestimonialDescription);
