import { useIntersectionObserver } from '@finn/ui-utils';
import React, {
  createContext,
  memo,
  PropsWithChildren,
  useMemo,
  useRef,
  useState,
} from 'react';

type TestimonialPlaybackContextState = {
  isInViewport: boolean;
  isMuted: boolean;
  mute(): void;
  unmute(): void;
};

const noop = () => {};

const defaultValue: TestimonialPlaybackContextState = {
  isInViewport: false,
  isMuted: true,
  mute: noop,
  unmute: noop,
};

export const TestimonialPlaybackContext =
  createContext<TestimonialPlaybackContextState>(defaultValue);

export const TestimonialPlaybackContextProvider: React.FC<
  PropsWithChildren<unknown>
> = memo(function TestimonialAudioContextProvider({ children }) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isInViewport = useIntersectionObserver(containerRef, 0, '200px');

  const [isMuted, setIsMuted] = useState(true);

  const value = useMemo(() => {
    return {
      isInViewport,
      isMuted,

      mute() {
        setIsMuted(true);
      },

      unmute() {
        setIsMuted(false);
      },
    };
  }, [isInViewport, isMuted]);

  return (
    <TestimonialPlaybackContext.Provider value={value}>
      <div ref={containerRef}>{children}</div>
    </TestimonialPlaybackContext.Provider>
  );
});
