export function getSlidePosition(currentIndex: number, index: number) {
  if (index < currentIndex) {
    return 'prev';
  }

  if (index > currentIndex) {
    return 'next';
  }

  return 'current';
}
