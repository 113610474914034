import { CTA } from '@finn/ui-components';
import { useIsMobile } from '@finn/ui-utils';
import { makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';

import { BREAKPOINT_SM_1_VALUE } from './Testimonial.constants';
import { TestimonialCosmicMetadata } from './Testimonial.types';
import TestimonialDescription from './TestimonialDescription';
import { TestimonialPlaybackContextProvider } from './TestimonialPlaybackContext';
import TestimonialSliderDesktop from './TestimonialSliderDesktop';
import TestimonialSliderMobile from './TestimonialSliderMobile';
import TestimonialTrustpilot from './TestimonialTrustpilot';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },

  inner: {
    [theme.breakpoints.down('xs')]: {
      $slider: {
        marginTop: 24,
      },
    },

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      padding: theme.spacing(6, 0, 0),
    },

    [theme.breakpoints.down(BREAKPOINT_SM_1_VALUE)]: {
      flexDirection: 'column',
    },
  },
  info: {
    flexBasis: `${(5 / 12) * 100}%`,
    flexShrink: 1,
  },

  slider: {
    flexBasis: `${(7 / 12) * 100}%`,
    flexShrink: 0,

    [theme.breakpoints.down(BREAKPOINT_SM_1_VALUE)]: {
      marginTop: 56,
    },

    [theme.breakpoints.down('md')]: {
      minWidth: 600,
    },

    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
  },
}));

type Props = {
  data: TestimonialCosmicMetadata;
};

const Testimonial: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  const renderContent = useCallback(() => {
    if (isMobile) {
      return (
        <section className={classes.root}>
          <div className="container">
            <div className={classes.info}>
              <TestimonialDescription data={data} />
              <TestimonialTrustpilot />

              {data.cta && (
                <CTA
                  textColor="black"
                  parentBG="light"
                  data={data.cta.metadata}
                />
              )}
            </div>
          </div>

          <div className={classes.slider}>
            <TestimonialSliderMobile slides={data.slides} />
          </div>
        </section>
      );
    }

    return (
      <section className={classes.root}>
        <div className="container">
          <div className={classes.inner}>
            <div className={classes.info}>
              <TestimonialDescription data={data} />
              <TestimonialTrustpilot />

              {data.cta && (
                <CTA
                  textColor="black"
                  parentBG="light"
                  data={data.cta.metadata}
                />
              )}
            </div>

            <div className={classes.slider}>
              <TestimonialSliderDesktop slides={data.slides} />
            </div>
          </div>
        </div>
      </section>
    );
  }, [
    classes.info,
    classes.inner,
    classes.root,
    classes.slider,
    data,
    isMobile,
  ]);

  return (
    <TestimonialPlaybackContextProvider>
      {renderContent()}
    </TestimonialPlaybackContextProvider>
  );
};

export default React.memo(Testimonial);
