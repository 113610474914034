import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { TestimonialSlideMetadata } from '~/modules/cosmic/modules/Testimonial';
import {
  BREAKPOINT_SM_1_VALUE,
  BREAKPOINT_SM_2_VALUE,
  SLIDE_CONTENT_WIDTH,
  SLIDE_TRANSITION_DESKTOP_MS,
} from '~/modules/cosmic/modules/Testimonial/Testimonial.constants';
import TestimonialVideoPreview from '~/modules/cosmic/modules/Testimonial/TestimonialVideoPreview';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  prev: {
    zIndex: 0,
  },

  current: {
    zIndex: 1,
  },

  next: {
    zIndex: 0,
  },

  animated: {
    '&, & $textContainer, & $video': {
      transition: `${SLIDE_TRANSITION_DESKTOP_MS}ms ease-in-out`,
      transitionOptions: 'opacity transform',
    },
  },

  hidden: {
    opacity: 0,
  },

  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: theme.spacing(7, 6, 6, 6),

    '$prev &': {
      opacity: 0,
    },

    '$next &': {
      opacity: 0,
    },
  },

  textCustomerInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },

  videoContainer: {
    position: 'relative',
    flexBasis: SLIDE_CONTENT_WIDTH,
    marginRight: 48,
    flexShrink: 0,

    [theme.breakpoints.up(BREAKPOINT_SM_1_VALUE)]: {
      flexBasis: SLIDE_CONTENT_WIDTH * 0.8,
    },

    [theme.breakpoints.up(BREAKPOINT_SM_2_VALUE)]: {
      flexBasis: SLIDE_CONTENT_WIDTH,
    },
  },

  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',

    '$prev &': {
      transform: 'scale(0.9) translateX(-45%)',
      opacity: 0,
    },

    '$current &': {
      transform: 'translate(0, -48px)',
    },

    '$next &': {
      transform:
        // Width of the image -> 48px spacing from borders -> 36px spacing between background & the video
        'translateX(100%) translateX(48px) translateX(36px) scale(0.8)',
      opacity: 0.5,
    },
  },
}));

type Props = {
  slide: TestimonialSlideMetadata;
  position: 'prev' | 'current' | 'next';
  isAnimated: boolean;
  isHidden: boolean;
  isRepeated: boolean;
};

const TestimonialSliderDesktopSlide: React.FC<Props> = ({
  slide,
  position,
  isAnimated,
  isHidden,
  isRepeated,
}) => {
  const classes = useStyles();

  const className = classNames(classes.root, classes[position], {
    [classes.animated]: isAnimated,
    [classes.hidden]: isHidden,
  });

  const timeoutRef = useRef(null);

  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    if (isRepeated) {
      return;
    }

    if (position === 'current') {
      timeoutRef.current = window.setTimeout(
        () => setIsActive(true),
        isAnimated ? SLIDE_TRANSITION_DESKTOP_MS : 0
      );
    } else {
      setIsActive(false);
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, [position]);

  return (
    <div className={className}>
      <div className={classes.textContainer}>
        <h4 className="global-t5-semibold text-white">{slide.description}</h4>

        <div className={classes.textCustomerInfo}>
          <h6 className="global-t6-semibold text-white">{slide.name}</h6>

          <span className="body-16-regular text-white">
            {slide.vehicle_model}
          </span>
        </div>
      </div>

      <div className={classes.videoContainer}>
        <div className={classes.video}>
          <TestimonialVideoPreview slide={slide} isActive={isActive} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(TestimonialSliderDesktopSlide);
