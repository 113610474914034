import { Button } from '@finn/design-system/atoms/button';
import { VolumeNo } from '@finn/design-system/icons/volume-no';
import { VolumeYes } from '@finn/design-system/icons/volume-yes';
import { Image } from '@finn/ui-components';
import { cn, getCloudinaryImgUrl } from '@finn/ui-utils';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { useCallback, useContext, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import {
  SLIDE_CONTENT_HEIGHT,
  SLIDE_CONTENT_WIDTH,
} from './Testimonial.constants';
import { TestimonialSlideMetadata } from './Testimonial.types';
import { TestimonialPlaybackContext } from './TestimonialPlaybackContext';

const VIDEO_FADE_IN_MS = 300;

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    cursor: 'pointer',
    border: 0,
    outline: 0,
    backgroundColor: 'transparent',
    display: 'block',
    padding: 0,
    overflow: 'hidden',

    // keen seems to do some manual calcultion
    // based on root size, to ensure proper sizing
    // we set a fixed width here for now
    width: SLIDE_CONTENT_WIDTH,
    height: SLIDE_CONTENT_HEIGHT,
  },

  active: {
    '& $actionBar': {
      opacity: 1,
    },
  },

  preview: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'contain',
  },

  actionBar: {
    position: 'absolute',
    bottom: -1,
    left: -1,
    right: -1,
    height: 36,
    backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.75), transparent)`,
    transition: 'opacity 100ms ease-in-out',
    opacity: 0,
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: 8,
  },

  playButton: {
    width: 24,
    height: 24,
    padding: 0,
    margin: 0,
    backgroundColor: 'transparent',
    border: 0,
    outline: 'none',
    cursor: 'pointer',
  },

  enter: {
    opacity: 0,
  },

  enterActive: {
    opacity: 1,
    transition: `opacity ${VIDEO_FADE_IN_MS}ms ease-in-out`,
  },

  exit: {
    opacity: 1,
    transition: `opacity ${VIDEO_FADE_IN_MS}ms ease-in-out`,
  },

  exitActive: {
    opacity: 0,
  },
}));

type Props = {
  slide: TestimonialSlideMetadata;
  isActive: boolean;
};

const TestimonialVideoPreview: React.FC<Props> = ({ slide, isActive }) => {
  const classes = useStyles();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const { isInViewport, isMuted, mute, unmute } = useContext(
    TestimonialPlaybackContext
  );

  const toggleMute = useCallback(() => {
    if (!isActive) {
      return;
    }

    if (videoRef.current && isMuted) {
      videoRef.current.currentTime = 0;
    }

    if (isMuted) {
      unmute();
    } else {
      mute();
    }
  }, [isActive, isMuted, mute, unmute]);

  return (
    <Button
      type="button"
      className={classNames(
        classes.root,
        isActive && classes.active,
        'h-full w-full hover:bg-transparent active:bg-transparent'
      )}
      onClick={toggleMute}
    >
      <svg viewBox={`0 0 ${SLIDE_CONTENT_WIDTH} ${SLIDE_CONTENT_HEIGHT}`} />
      <Image
        variant="lazy-loading"
        alt={slide.name}
        src={getCloudinaryImgUrl(slide.preview.url, {
          dpr: 2,
          w: 240,
        })}
        className={classes.preview}
        width={SLIDE_CONTENT_WIDTH}
        height={SLIDE_CONTENT_HEIGHT}
      />
      {slide?.video?.url && (
        <>
          <CSSTransition
            mountOnEnter
            unmountOnExit
            in={isActive && isInViewport}
            timeout={VIDEO_FADE_IN_MS}
            classNames={classes}
          >
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              ref={videoRef}
              autoPlay
              playsInline
              muted={isMuted}
              loop={isMuted}
              className={classes.preview}
              src={slide.video.url}
              width={SLIDE_CONTENT_WIDTH}
              height={SLIDE_CONTENT_HEIGHT}
            />
          </CSSTransition>
          <CSSTransition
            mountOnEnter
            unmountOnExit
            in={isActive}
            timeout={VIDEO_FADE_IN_MS}
            classNames={classes}
          >
            <div className={classes.actionBar}>
              <div
                className={cn(classes.playButton, 'text-white')}
                onClick={toggleMute}
              >
                {isMuted ? (
                  <VolumeNo className="fill-white" />
                ) : (
                  <VolumeYes className="fill-white" />
                )}
              </div>
            </div>
          </CSSTransition>
        </>
      )}
    </Button>
  );
};

export default React.memo(TestimonialVideoPreview);
